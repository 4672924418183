import React, {FC, useContext, useEffect, ReactNode} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';

import {UserContext} from '../../../helpers/contexts/user-context';

import WhoisResult from '../whois-domain-lookup/result';
import Grammar from '../grammar-spell-checker/result';
import Accsesibility from '../accessibility-audit/result';
import TextToSpeechAction from '../text-to-speech/action';
import TeckStack from '../tech-stack-analyzer/result';
import WordsCountResult from '../word-count/word-count֊result';
import NothingToShow from '../../nothingToShow';
import ScreenshotCaptureWrapper from '../screenshot-capture/result';
import QRGeneratorResult from '../qr-generator/qr-generator֊result';

const ToolResult: FC<{result: any; setUser: any; loading: boolean}> = ({result, setUser, loading}) => {
  const {user} = useContext(UserContext);
  const toolsListComponents: {[key: string]: ReactNode} =
    result && result.result && result.result[0]
      ? {
          TECH_STACK: <TeckStack result={result.result[0]['_tech_stack']} url={result.source} />,
          WHO_IS: <WhoisResult result={result.result[0]} url={result.source} />,
          ACCESSIBILITY_CHECKER: <Accsesibility result={result.result[0]} url={result.source} />,
          GRAMMAR_SPELLING: <Grammar result={result.result[0]} url={result.source}/>,
          TEXT_TO_SPEECH: <TextToSpeechAction source={result.source} file={result.result[0]?._text_to_speech?.[0]} />,
          GET_WORD_COUNT: <WordsCountResult data={result.result[0]} url={result.source} />,
          DEVICE_SCREENSHOT: (
            <ScreenshotCaptureWrapper
              result={Object.values(result.result[0]).filter(item => typeof item === 'object')}
              url={result.source}
              selectedDevice={result.params && result.params[0] && result.params[0].devices && result.params[0].devices[0]}
            />
          ),
          QR_CODE: <QRGeneratorResult data={result.result[0]} url={result.source} />,
        }
      : {};

  useEffect(() => {
    // if (user && Object.keys(user) && Object.keys(user).length && !user.id) navigate('/#login');
    if (user && Object.keys(user) && Object.keys(user).length && user.id) setUser(user);
  }, [user]);
  return (
    <>
      {user && !user.email && !loading ? (
        <div className="m-auto">
          <NothingToShow />
        </div>
      ) : loading || !user || !Object.keys(user) || !result || !result.result || !result.result[0] ? (
        <div className="mx-auto mb-5">
          <FontAwesomeIcon icon={faSpinner} className="button-loading" />
        </div>
      ) : (
        toolsListComponents[result.toolName]
      )}
    </>
  );
};

export default ToolResult;
