import React from 'react';
import {Link, navigate} from 'gatsby';
// import FileSaver from 'file-saver';

import {makeValidHref} from '../../../helpers';
import {downlodIconBtn} from '../../../images/svgIcons';

const GrammarResult = (props: any) => {
  if (
    (props?.result?._grammar_spelling_audit_screenshot_image &&
      props.result._grammar_spelling_audit_screenshot_image[0] === 'Not found.') ||
    !props.result._grammar_spelling_audit_issues_found
  ) {
    return (
      <div className="my-5 d-flex flex-column w-100">
        <span className="go-back" onClick={() => navigate(-1)}>
          ← Go Back
        </span>
        <div className="col-12 grammar-result">
          <h5 className="text-center">Page not found</h5>
        </div>
      </div>
    );
  }

  const handleClick = async () => {
    const url = props.result._pdf_url[0];
    // const file = url && await fetch(url);
    // const blob = file && await file.blob();
    // blob && FileSaver.saveAs(blob, `${cleanShortURL(props.scanUrl || props.url)}-grammar-spelling.pdf`);
    url && window.open(url, '_blank', 'noopener');
  };

  return (
    <div className="d-block m-auto">
      {props.url && (
        <div className="d-flex m-auto" style={{maxWidth: '900px', width: '100%'}}>
          <div>
            <Link to="/tools/history/#grammar-spelling-audit">← Back to history</Link>
          </div>
          <div className="ml-auto" style={{maxWidth: 'calc(100% - 150px)', whiteSpace: 'nowrap', display: 'flex'}}>
            <div className="mr-1">Result for</div>
            <a
              title={props.url}
              href={makeValidHref(props.url)}
              target="_blank"
              className="text-truncate"
              style={{width: 'calc(100% - 50px)', display: 'inline-block'}}
              rel="noopener"
            >
              {props.url}
            </a>
          </div>
        </div>
      )}
      <div className="m-auto col-12 grammar-result-wrapper">
        <div className="d-sm-flex justify-content-between align-items-center">
          <p>Total issues found ({props.result._grammar_spelling_audit_issues_found[0]})</p>
          <p className={`d-flex align-items-center  justify-content-center download-btn mt-2`} onClick={handleClick}>
            Download {downlodIconBtn}
          </p>
        </div>

        <div className=" grammar-result">
          {!props.result._grammar_spelling_audit_screenshot_image ? (
            <h5 className="text-center">Page not found</h5>
          ) : (
            <>
              <img
                className="grammar-img"
                src={props.result._grammar_spelling_audit_screenshot_image[0]}
                alt="grammar-img"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default GrammarResult;
