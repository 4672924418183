import React, {useState} from 'react';
import {Link, navigate} from 'gatsby';
import dayjs from 'dayjs';

import {isoDateToHtmlDate} from '../../../helpers/constants';
import {makeValidHref} from '../../../helpers';

const WhoisResult = (props: any) => {
  const [isShow, setIsShow] = useState(false);
  const [isShowDns, setIsShowDns] = useState(false);
  const getDate = (date: string, type?: string) => isoDateToHtmlDate(date, type || 'registeredAt');
  const handleShow = () => setIsShow(!isShow);
  const handleShowDns = () => setIsShowDns(!isShowDns);
  if (props.result._whois_status[0] === 'Not Available' || props.result._whois_status[0] === 'Not found.')
    return (
      <>
        <span className="go-back" onClick={() => navigate(-1)}>
          ← Go Back
        </span>
        <div className="col-12 whois-result">
          <h5 className="text-center">Page not found</h5>
        </div>
      </>
    );
  const dnsData =
    props.result._whois_dnsData &&
    props.result._whois_dnsData !== 'Not found.' &&
    Object.entries(JSON.parse(props.result._whois_dnsData));

  return (
    <div className="w-100 mt-4">
      {props.url && (
        <div className="d-flex m-auto pb-2" style={{maxWidth: '741px', width: '90%'}}>
          <div>
            <Link to="/tools/history/#domain-whois">← Back to history</Link>
          </div>
          <div className="ml-auto" style={{maxWidth: 'calc(100% - 150px)', whiteSpace: 'nowrap', display: 'flex'}}>
            <div className="mr-1">Result for</div>
            <a
              title={props.url}
              href={makeValidHref(props.url)}
              target="_blank"
              className="text-truncate"
              style={{width: 'calc(100% - 50px)', display: 'inline-block'}}
              rel='noopener'
            >
              {props.url}
            </a>
          </div>
        </div>
      )}
      <div className="col-12 whois-result w-100">
        {props.result._whois_status[0] === 'Not Available' ? (
          <h5 className="text-center">Page not found</h5>
        ) : (
          <>
            <h5 className="text-center">Domain information</h5>
            <div className="info-wrapper">
              <div className="d-flex justify-content-between light-bg">
                <div>Domain Age:</div>
                <div>{isoDateToHtmlDate(props.result._whois_registeredAt[0], 'domainAge')}</div>
              </div>
              <div className="d-flex justify-content-between light-bg">
                <div>Created Date:</div>
                <div>{dayjs(getDate(props.result._whois_registeredAt[0])).format('MMM DD, YYYY')}</div>
              </div>
              <div className="d-flex justify-content-between light-bg">
                <div>Updated:</div>
                <div>{dayjs(getDate(props.result._whois_lastModified[0])).format('MMM DD, YYYY')}</div>
              </div>
              <div className="d-flex justify-content-between light-bg">
                <div>Expires in:</div>
                <div>{getDate(props.result._whois_expiresAt[0], 'expiresAt')}</div>
              </div>
              <div className="d-flex justify-content-between light-text">
                <div>WHOIS Raw Data</div>
                <div className="bordered"></div>
                <div className="plus" onClick={handleShow}>
                  {!isShow ? '+' : '-'}
                </div>
              </div>
              {isShow && (
                <div className="raw-info">
                  <p>{props.result._whois_fullText[0].replaceAll('%', '')}</p>
                </div>
              )}
              <div className="d-flex justify-content-between light-text">
                <div>DNS Records</div>
                <div className="bordered"></div>
                <div className="plus" onClick={handleShowDns}>
                  {!isShowDns ? '+' : '-'}
                </div>
              </div>
              {isShowDns && dnsData && (
                <div className="dns">
                  {dnsData.map((item: any, index: number) => (
                    <div className={`dns-wrap-${index % 2 === 0 ? 'odd' : 'even'}`}>
                      <div className="dns-name">{item[0]}</div>
                      <div className="dns-info">
                        {Array.isArray(item[1])
                          ? item[1].length > 0 &&
                            item[1].map((subItem: any) => (
                              <div>
                                {typeof subItem === 'object'
                                  ? Object.entries(subItem).map((it: any) => (
                                      <div>
                                        {it[0]} - {it[1]}
                                      </div>
                                    ))
                                  : JSON.stringify(subItem).replaceAll('"', '')}
                              </div>
                            ))
                          : typeof item[1] === 'object' &&
                            Object.entries(item[1]).map((item: any) => (
                              <div>
                                {item[0]} - {item[1]}
                              </div>
                            ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default WhoisResult;
