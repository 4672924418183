import React from 'react';
import {Link, navigate} from 'gatsby';
// import FileSaver from 'file-saver';

import {makeValidHref} from '../../../helpers';
import {downlodIconBtn} from '../../../images/svgIcons';

const Result = (props: any) => {
  if (props.result[0][0] === 'Not found.')
    return (
      <div className="my-5 d-flex flex-column w-100">
        <span className="go-back" onClick={() => navigate(-1)}>
          ← Go Back
        </span>
        <div className="col-12 accessibility-result">
          <h5 className="text-center">Page not found</h5>
        </div>
      </div>
    );

  const handleClick = async () => {
    const url = props.result[0][0].replace('///', '/');
    // const file = url && await fetch(url);
    // const blob = file && await file.blob();
    // blob && FileSaver.saveAs(blob, `${cleanShortURL(props.scanUrl || props.url)}-${(props.selectedDevice || 'screenshot').toLocaleLowerCase()}.png`);
    url && window.open(url, '_blank', 'noopener');
  };

  return (
    <div className="m-auto" style={{maxWidth: '900px', width: '100%'}}>
      {props.url && (
        <div className="d-flex m-auto" style={{maxWidth: '785px', width: '90%'}}>
          <div>
            <Link to="/tools/history/#screenshot-capture">← Back to history</Link>
          </div>
          <div className="ml-auto" style={{maxWidth: 'calc(100% - 150px)', whiteSpace: 'nowrap', display: 'flex'}}>
            <div className="mr-1">Result for</div>
            <a
              title={props.url}
              href={makeValidHref(props.url)}
              target="_blank"
              className="text-truncate"
              style={{width: 'calc(100% - 50px)', display: 'inline-block'}}
              rel="noopener"
            >
              {props.url}
            </a>
          </div>
        </div>
      )}
      <div className="m-auto screenshot-result-wrapper">
        <p className={`d-flex align-items-center justify-content-center download-btn mt-2 mb-2`} onClick={handleClick}>
          Download {downlodIconBtn}
        </p>
        <div className="accessibility-result text-center">
          {!props.result[0][0] ? (
            <h5 className="text-center">Page not found</h5>
          ) : (
            <>
              <img className="grammar-img" src={props.result[0][0].replace('///', '/')} alt="grammar-img" />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Result;
