import React, {useEffect, useState} from 'react';
import Layout from '../../../../components/layout/layout';
import {PageHero} from '../../../../components/page-hero';
import Section from '../../../../components/section';
import ToolResult from '../../../../components/tools-components/history/element';
import {toolsListTitle} from '../../../../helpers/constants';
import {toolHistoryRequestById} from '../../../../requests';

const Tool = (props: any) => {
  const id = props['*'];
  const [result, setResult] = useState({});
  const [titles, setTitles] = useState({title: '', subTitle: ''});
  const [user, setUser] = useState<{id?: number}>({});
  const [loading, setLoading] = useState(true);

  const getResult = async () => {
    setLoading(true);
    const res = await toolHistoryRequestById(id);
    if (res) {
      setLoading(false);
      setResult(res);
      res.toolName && setTitles(toolsListTitle[res.toolName]);
    }
  };
  useEffect(() => {
    if (user && user.id) {
      getResult();
    }
  }, [id, user]);

  return (
    <Layout>
      <PageHero title={titles?.title || ''} subtitle={titles?.subTitle || ''} titleSmall className="toolPage" />
      <Section>
        <ToolResult result={result} setUser={setUser} loading={loading} />
      </Section>
    </Layout>
  );
};
export default Tool;
